<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{formFields.id ? `Edit ${operationTitle}` : `Add ${operationTitle}`}}
          </template>
          <template v-slot:toolbar>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>
          <template v-slot:body>
            <v-card>
              <div class="itineraries-operation">
                <b-container fluid>
                  <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                    <b-row>
                      <b-col class="p-0" cols="12" lg="12" md="12" sm="12">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                :invalid-feedback="formErrors.first('title')"
                                label="Title *"
                                label-for="title"
                            >
                              <b-form-input
                                  :state="((formErrors.has('title') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="title"
                                  type="text"
                                  v-model="formFields.title"
                              ></b-form-input>
                            </b-form-group>
                          </b-col><!--/b-col-->
                          <b-col sm="6">
                            <b-form-group
                                :invalid-feedback="formErrors.first('date')"
                                label="Date *"
                                label-for="date"
                            >
                              <b-form-datepicker
                                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                  :state="((formErrors.has('date') ? false : null))"
                                  close-button
                                  id="date"
                                  reset-button
                                  today-button
                                  type="text"
                                  v-model="formFields.date"
                              ></b-form-datepicker>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row><!--/b-row-->
                        <b-row>
                          <b-col sm="6">
                            <b-form-group
                                :invalid-feedback="formErrors.first('start_time')"
                                label="Start Time *"
                                label-for="start_time"
                            >
                              <treeselect
                                  :options="_.map(dropdowns.fromTime,(i) => {
                                                                    return {
                                                                        id:i,
                                                                        label:i
                                                                    }
                                                                })"
                                  v-model="formFields.start_time"
                              />
                              <span class="text-danger"
                                    v-if="formErrors.has('start_time')">
                                                                {{ formErrors.first("start_time") }}
                                                            </span>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group
                                :invalid-feedback="formErrors.first('end_time')"
                                label="End Time *"
                                label-for="end_time"
                            >
                              <treeselect
                                  :options="_.map(dropdowns.toTime,(i) => {
                                                                    return {
                                                                        id:i,
                                                                        label:i
                                                                    }
                                                                })"
                                  v-model="formFields.end_time"
                              />
                              <span class="text-danger"
                                    v-if="formErrors.has('end_time')">
                                                                    {{ formErrors.first("end_time") }}
                                                                </span>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="12">
                            <b-form-group
                                :invalid-feedback="formErrors.first('description')"
                                label="Description"
                                label-for="description"
                            >
                              <b-form-textarea
                                  :state="((formErrors.has('description') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="description"
                                  type="number"
                                  v-model="formFields.description"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <b-row>
                          <b-col sm="12">
                            <b-form-group
                                :invalid-feedback="formErrors.first('trigger_message')"
                                label="Trigger Message"
                                label-for="trigger_message"
                            >
                              <b-form-textarea
                                  :state="((formErrors.has('trigger_message') ? false : null))"
                                  @focus="$event.target.select()"
                                  id="trigger_message"
                                  type="number"
                                  v-model="formFields.trigger_message"
                              ></b-form-textarea>
                            </b-form-group>
                          </b-col><!--/b-col-->
                        </b-row>
                        <hr />
                        <b-row class="operation-footer">
                          <b-col sm="12">
                            <b-button
                                :disabled="global.pendingRequests > 0"
                                size="sm"
                                type="submit"
                                variant="primary"
                            >
                              <i class="fa fa-spin fa-spinner"
                                 v-show="global.pendingRequests > 0"></i>
                              <i class="fa fa-save fa-sm"></i>
                              Save
                            </b-button>
                            <b-button @click="handleOperationClose()" class="ml-3"
                                      size="sm"
                                      variant="danger">
                              <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col><!--/b-col-->
                    </b-row><!--/b-row-->
                  </form><!--/form-->
                </b-container><!--/b-container-->
              </div><!--/.itineraries-operation-->
            </v-card>
          </template>
        </KTCard>
      </div>
    </div>
  </div>
</template>

<script>
  import KTCard from "@/view/content/Card.vue";
  import moduleMixin from "./module.mixin";
  import { mapState } from "vuex";

  export default {
    mixins: [moduleMixin],
    components: {
      KTCard,
    },
    mounted() {
      if ( this.$route.params && this.$route.params.id && this.$route.params.type ) {
        this.handleEditOperation(this.$route.params.id);
      }
    },
    computed: {
      ...mapState([
        'global',
      ]),
    },
  };
</script>
